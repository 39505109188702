<template>
  <v-form
    v-if="item"
    @submit.prevent="save()"
  >
    <ValidationObserver
      ref="validationObserver"
      v-slot="{ invalid }"
    >
      <v-card
        :style="{ position: 'relative' }"
      >
        <v-card-title>
          <h5
            class="ma-0"
          >
            {{ exists ? 'Edit' : 'Create' }} Network
          </h5>
        </v-card-title>
        <template
          v-if="exists"
        >
          <v-divider/>
          <v-list
            class="body-2"
            dense
          >
            <v-list-item>
              <v-list-item-content>
                Client
              </v-list-item-content>
              <v-list-item-content>
                <div
                  class="text-right"
                >
                  <a
                    class="text-decoration-none"
                    :href="myTribusClientUrl"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <v-icon
                      small
                    >
                      launch
                    </v-icon>
                  </a>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                Created
              </v-list-item-content>
              <v-list-item-content>
                <div
                  class="text-right"
                >
                  {{ formatDateTime(item.created_at) }}
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                {{ item.deleted_at ? 'Deleted' : 'Updated' }}
              </v-list-item-content>
              <v-list-item-content>
                <div
                  class="text-right"
                >
                  {{ formatDateTime(item.deleted_at || item.updated_at) }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <v-divider/>
        <v-card-text>
          <v-alert
            :color="notification.color"
            dismissible
            text
            v-if="notification.display"
            v-model="notification.display"
          >
            {{ notification.message }}
          </v-alert>
          <ValidationProvider
            name="UID"
            rules="required|uuid:4"
            v-slot="{ errors }"
          >
            <v-text-field
              class="required"
              :error-messages="errors"
              :disabled="exists || isDisabled"
              label="UID"
              v-model="item.uid"
            />
          </ValidationProvider>
          <ValidationProvider
            name="customer type"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="item.customer_type"
              class="required"
              :error-messages="errors"
              item-text="label"
              :items="customerTypes.items"
              :loading="customerTypes.isLoading"
              label="Customer Type"
              return-object
            />
          </ValidationProvider>
          <template
            v-if="!customerTypes.isLoading && item.customer_type.domain !== undefined"
          >
            <ValidationProvider
              name="subdomain"
              rules="required|alpha_num|max:32|lowercase"
              v-slot="{ errors }"
            >
              <v-text-field
                class="required mb-2"
                :disabled="isDisabled"
                :error-messages="errors"
                label="Subdomain"
                :loading="customerTypes.isLoading"
                :suffix="`.${item.customer_type.domain}`"
                v-model="item.subdomain"
              />
            </ValidationProvider>
            <v-card
              class="mb-4"
            >
              <v-card-title>
                <h6>
                  Feature Flags
                </h6>
              </v-card-title>
              <v-divider />
              <div>
                <v-card-text
                  v-if="featureFlags.isLoading"
                  class="text-center"
                >
                  <v-progress-circular
                    color="primary"
                    indeterminate
                  />
                </v-card-text>
                <v-card-text
                  v-else-if="featureFlags.failedToLoad"
                >
                  <v-icon
                    class="mr-2"
                    small
                  >
                    warning
                  </v-icon>
                  Something unexpected occurred while loading feature flags.
                </v-card-text>
                <template
                  v-else
                >
                  <v-data-table
                    :headers="featureFlags.headers"
                    hide-default-footer
                    :items="Object.keys(config.FEATURE_FLAGS)"
                    :items-per-page="-1"
                  >
                    <template
                      v-slot:item="{ item: key }"
                    >
                      <tr>
                        <td>
                          {{ config.FEATURE_FLAGS[key].label }}
                        </td>
                        <td>
                          <v-checkbox
                            class="ma-0"
                            color="secondary"
                            :disabled="isDisabled"
                            hide-details
                            :input-value="featureFlags.pendingValues[key] === 'allow'"
                            :readonly="featureFlags.pendingValues[key] === 'allow'"
                            @change="featureFlags.pendingValues[key] = 'allow'"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            class="ma-0"
                            color="secondary"
                            :disabled="isDisabled"
                            hide-details
                            :input-value="featureFlags.pendingValues[key] === 'deny'"
                            :readonly="featureFlags.pendingValues[key] === 'deny'"
                            @change="featureFlags.pendingValues[key] = 'deny'"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            class="ma-0"
                            color="secondary"
                            :disabled="isDisabled"
                            hide-details
                            :input-value="featureFlags.pendingValues[key] === 'inherit'"
                            :label="getDefaultFeatureLabel(key)"
                            :readonly="featureFlags.pendingValues[key] === 'inherit'"
                            @change="featureFlags.pendingValues[key] = 'inherit'"
                          />
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <v-divider />
                  <v-card-text
                    class="pt-0"
                  >
                    <v-row>
                      <v-col
                        v-for="(feature, index) in deprecatedFeatureFlags"
                        :key="`deprecated_feature_flag_${index}`"
                        :cols="$vuetify.breakpoint.mobile ? 12 : 4"
                      >
                        <v-checkbox
                          color="secondary"
                          :disabled="isDisabled"
                          hide-details
                          :label="feature.label"
                          v-model="item[feature.key]"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </template>
              </div>
            </v-card>
            <v-card class="mb-4">
              <v-card-title>
                <h6>
                  Terms of Service
                </h6>
              </v-card-title>
              <v-divider/>
              <v-card-text>
                <ValidationProvider
                  name="URL"
                  :rules="`${requireTermsOfServiceUrl ? 'required|' : ''}max:255|url`"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    :class="{ 'required': requireTermsOfServiceUrl }"
                    :disabled="isDisabled"
                    :error-messages="errors"
                    label="URL"
                    v-model="item.terms_of_service_url"
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="description"
                  rules="max:255"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    :disabled="isDisabled"
                    :error-messages="errors"
                    label="Description"
                    v-model="item.terms_of_service_description"
                  />
                </ValidationProvider>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title>
                <h6>
                  Other
                </h6>
              </v-card-title>
              <v-divider />
              <v-card-text>
                <ValidationProvider
                  name="recommended user photo size message"
                  rules="max:500"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    :disabled="isDisabled"
                    :error-messages="errors"
                    label="Recommended User Photo Size Message"
                    v-model="item.recommended_user_photo_size_message"
                  />
                </ValidationProvider>
              </v-card-text>
            </v-card>
          </template>
        </v-card-text>
        <template
          v-if="!isDisabled"
        >
          <v-divider/>
          <v-card-actions>
            <v-btn
              color="secondary"
              :disabled="invalid"
              type="submit"
            >
              {{ exists ? 'Update' : 'Create' }}
            </v-btn>
            <v-spacer/>
            <v-btn
              @click="openConfirmDeletionDialog()"
              color="red darken-3"
              text
              v-if="exists"
            >
              Archive
            </v-btn>
          </v-card-actions>
        </template>
        <Loader
          v-if="isLoading"
        />
      </v-card>
    </ValidationObserver>
    <v-dialog
      width="500"
      v-model="dialogs.confirmDeletion.display"
    >
      <v-form
        @submit.prevent="destroy()"
      >
        <v-card>
          <v-card-title>
            <h5 class="ma-0">
              Confirm Archival
            </h5>
          </v-card-title>
          <v-card-text>
            Type "{{ item.subdomain }}" to confirm archival.
            <v-text-field
              class="mt-4"
              hide-details
              label="Subdomain"
              outlined
              v-model="dialogs.confirmDeletion.confirmation"
            />
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              color="red darken-3"
              :disabled="dialogs.confirmDeletion.confirmation !== item.subdomain"
              text
              type="submit"
            >
              Archive
            </v-btn>
            <v-btn
              @click="dialogs.confirmDeletion.display = false"
              text
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-form>
</template>

<script>
import { cloneDeep, upperFirst } from 'lodash';
import { FEATURE_FLAGS, isEmptyString } from 'displet-vue';

import asyncPoolAll from '../helpers/asyncPoolAll';
import formatDateTime from '../formatters/formatDateTime';
import Loader from './Loader.vue';

export default {
  name: 'NetworkForm',

  components: {
    Loader,
  },

  props: {
    item: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      config: {
        FEATURE_FLAGS,
      },
      customerTypes: {
        isLoading: false,
        items: [],
      },
      deprecatedFeatureFlags: [
        {
          label: 'Coach',
          key: 'has_coach',
        },
        {
          label: 'Premium CRM',
          key: 'has_premium_crm',
        },
        {
          label: 'Property Marketing',
          key: 'has_property_marketing',
        },
        {
          label: 'Support',
          key: 'has_support',
        },
        {
          label: 'Website(s)',
          key: 'has_websites',
        },
      ],
      dialogs: {
        confirmDeletion: {
          confirmation: null,
          display: false,
        },
      },
      featureFlags: {
        currentValues: {},
        failedToLoad: false,
        headers: [
          {
            sortable: false,
            text: 'Feature',
            value: 'label',
          },
          {
            sortable: false,
            text: 'Allow',
            value: 'value',
          },
          {
            sortable: false,
            text: 'Deny',
            value: 'value',
          },
          {
            sortable: false,
            text: 'Inherit',
            value: 'value',
          },
        ],
        isLoading: false,
        pendingValues: {},
        persistedItems: [],
      },
      isLoading: false,
      notification: {
        color: null,
        display: false,
        message: null,
      },
    };
  },

  computed: {
    /** @type {boolean} */
    exists() {
      return !!this.item.created_at;
    },

    /** @type {boolean} */
    isDisabled() {
      return !!this.item.deleted_at;
    },

    /** @type {string} */
    myTribusClientUrl() {
      return `http://${this.item.subdomain}.${this.item.customer_type.domain}`;
    },

    /** @type {boolean} */
    requireTermsOfServiceUrl() {
      if (this.featureFlags.pendingValues.terms_of_service_require_acceptance === 'allow') {
        return true;
      }

      return this.config.FEATURE_FLAGS
        .terms_of_service_require_acceptance
        .customerTypeDefaults[this.item.customer_type.key];
    },
  },

  async created() {
    if (!this.exists) {
      this.item.customer_type = {};
    }

    await this.loadCustomerTypes();

    if (this.exists) {
      await this.loadFeatureFlags();
    }

    this.setFeatureFlagValues();
  },

  methods: {
    /** @return {string} */
    formatDateTime,

    /** @return {string} */
    getDefaultFeatureLabel(key) {
      return this.config.FEATURE_FLAGS[key].customerTypeDefaults[this.item.customer_type.key]
        ? '(Allow)'
        : '(Deny)';
    },

    /** @return {Promise<void>) */
    async loadCustomerTypes() {
      this.customerTypes.isLoading = true;

      try {
        const response = await this.$fetch('/admin/customer_types?sort_by=label&sort_order=asc');
        this.customerTypes.items = (await response.json()).results;
      } catch (e) {
        //
      } finally {
        this.customerTypes.isLoading = false;
      }
    },

    /** @return {Promise<void>} */
    async loadFeatureFlags() {
      if (!this.exists) {
        return;
      }

      this.featureFlags.isLoading = true;

      try {
        const queryString = `?per_page=100${!isEmptyString(this.item.deleted_at) ? '&is_deleted=1' : ''}`;
        const response = await this.$fetch(`/admin/networks/${this.$route.params.networkId}/feature_flags${queryString}`);

        this.featureFlags.persistedItems = (await response.json()).results;
      } catch (e) {
        this.featureFlags.failedToLoad = true;
      } finally {
        this.featureFlags.isLoading = false;
      }
    },

    /** @return {void} */
    openConfirmDeletionDialog() {
      this.dialogs.confirmDeletion.confirmation = null;
      this.dialogs.confirmDeletion.display = true;
    },

    /** @return {Promise<void>} */
    async destroy() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      const response = await this.$fetch(`/admin/networks/${this.item.uid}`, {
        method: 'DELETE',
      });

      this.isLoading = false;

      if (response.status !== 200) {
        this.dialogs.confirmDeletion.display = false;
        this.setNotification('red', 'Something unexpected occurred.');
        return;
      }

      await this.$router.push({
        name: 'network.index',
      });
    },

    /** @return {Promise<void>} */
    async save() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      await this.saveNetwork();
      await this.updateFeatureFlags();

      this.isLoading = false;
    },

    /** @return {Promise<void>} */
    async saveNetwork() {
      const uri = this.exists
        ? `/admin/networks/${this.item.uid}`
        : '/admin/networks';

      const method = this.exists
        ? 'PATCH'
        : 'POST';

      this.item.customer_type_id = this.item.customer_type.id;

      this.item.customer_type_id = this.item.customer_type.id;

      const response = await this.$fetch(uri, {
        body: JSON.stringify(this.item),
        headers: {
          'Content-Type': 'application/json',
        },
        method,
      });

      if (response.status === 200) {
        this.$emit('update:item', await response.json());

        this.setNotification('green', 'Successfully updated.');
      } else if (response.status === 400) {
        const body = await response.json();

        const errorMessages = {};

        body.message.messages.forEach((message) => {
          errorMessages[message.field] = upperFirst(message.message);
        });

        this.$refs.validationObserver.setErrors(errorMessages);

        this.setNotification('red', 'Please ensure everything is entered correctly.');
      } else {
        this.setNotification('red', 'Something unexpected occurred while saving the network.');
      }
    },

    /** @return {void} */
    setFeatureFlagValues() {
      const featureFlagValues = {};

      Object.keys(FEATURE_FLAGS).forEach((key) => {
        const persistedFeature = this.featureFlags.persistedItems
          .find((feature) => feature.key === key);

        let value = 'inherit';

        if (persistedFeature !== undefined) {
          value = !persistedFeature.is_enabled
            ? 'deny'
            : 'allow';
        }

        featureFlagValues[key] = value;
      });

      this.featureFlags.currentValues = cloneDeep(featureFlagValues);
      this.featureFlags.pendingValues = cloneDeep(featureFlagValues);
    },

    /** @return {void} */
    setNotification(color, message) {
      this.notification.color = color;
      this.notification.message = message;
      this.notification.display = true;
    },

    /** @return {Promise<void>} */
    async updateFeatureFlags() {
      const {
        currentValues,
        failedToLoad,
        persistedItems,
        pendingValues,
      } = this.featureFlags;

      if (failedToLoad) {
        return;
      }

      const keysOfItemsToUpdate = Object
        .keys(pendingValues)
        .filter((key) => pendingValues[key] !== currentValues[key]);

      try {
        await asyncPoolAll(6, keysOfItemsToUpdate, (key) => {
          const persistedItem = persistedItems.find((item) => item.key === key);
          const exists = persistedItem !== undefined;
          const uri = exists
            ? `/admin/networks/${this.item.uid}/feature_flags/${persistedItem.id}`
            : `/admin/networks/${this.item.uid}/feature_flags`;

          let body;
          let method;

          if (pendingValues[key] !== 'inherit') {
            body = JSON.stringify({
              key,
              is_enabled: pendingValues[key] === 'allow',
            });
            method = exists ? 'PATCH' : 'POST';
          } else {
            method = 'DELETE';
          }

          return this.$fetch(uri, {
            body,
            headers: {
              'Content-Type': 'application/json',
            },
            method,
          });
        });
      } catch (e) {
        this.setNotification('red', 'Something unexpected occurred while saving feature flags.');
      }

      await this.loadFeatureFlags();
      this.setFeatureFlagValues();
    },
  },
};
</script>
